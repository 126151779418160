import { LAST_PAGE_KEY } from '@/inc/app.config'
import ismobilejs from 'ismobilejs'
import Cookies from 'js-cookie'

export const enableDashboard = () => {
  window.localStorage.setItem('d', '1')
}

export const disableDashboard = () => {
  window.localStorage.removeItem('d')
}

/**
 * Dashboard is enabled when users uses login bypass (?u=xxx)
 *
 * Dashboard is disabled if there is login token in url (?token=xxx)
 */
export const isDashboardEnabled = () => Boolean(window.localStorage.getItem('d') === '1')

export const isMobile = () => ismobilejs(window.navigator)
export const isLastPageRedirectEnabled = Cookies.get(LAST_PAGE_KEY) && document.referrer.includes('mymortgages-gtu')
