























































import { defineComponent, ref } from '@vue/composition-api'
import { useGetters, useMutations } from '@u3u/vue-hooks'

import { userProfile } from '@/inc/endpoints'
import { NotificationSeverity } from '@/inc/types'
import callApi from '@/composables/fetchapi'

import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'
import Container from './Container.vue'

export const emailSessionUsageKey = 'elantis-email-usage-popup'

export default defineComponent({
  name: 'email',
  components: { Container, FeedbackMessage },
  props: {},

  setup(props, ctx) {
    const { SET_USER } = useMutations('user', ['SET_USER'])
    const { user } = useGetters('user', ['user'])

    const isLoading = ref(false)
    const feedbackMessage = ref<{ type: NotificationSeverity; htmltext: string } | null>()

    const onClose = () => {
      ctx.emit('close')
      sessionStorage.setItem(emailSessionUsageKey, '1')
    }

    const onSubmit = () => {
      isLoading.value = true
      user.value.gdprOptin = true

      callApi(userProfile, { method: 'put', data: user.value })
        .then(() => {
          SET_USER(user.value)
          ctx.emit('accepted')
          onClose()
        })
        .catch(() => {
          feedbackMessage.value = {
            type: 'error',
            htmltext: 'common.formError',
          }
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    return {
      onClose,
      isLoading,
      feedbackMessage,
      onSubmit,
      user,
    }
  },
})
