import { Language } from '@/inc/types'

export const USER_TOKEN = 'elantis-user-token'
export const LOGIN_TOKEN = 'elantis-login-token'
export const LOCAL_DATA_KEY = 'elantis-user-space'
export const LAST_PAGE_KEY = 'elantis-last-page'
export const CURRENT_VERSION = '0.0.17'

/**
 * Languages
 */
export const langDefault: Language = 'fr'
export const langAvailable = ['fr', 'nl'] as const

/**
 * URLs, segments, endpoints
 */
export const segments = {
  // eslint-disable-next-line
  // foo: ['bar', 'baz'],
} as Record<string, string | string[]>
export const endpointDefault = 'pages'

// API URLs
export const getApiUrl = () => `${process.env.VUE_APP_API_DOMAIN}${process.env.VUE_APP_API_PATH}`
export const getThumborUrl = () => `${process.env.VUE_APP_API_DOMAIN}${process.env.VUE_APP_THUMBOR_PATH}`

/**
 * GTM IDs
 */
export const gtm = {
  production: 'GTM-N588ZJX',
  staging: 'GTM-N588ZJX',
}

/**
 * Options
 */
export const isUnderMaintenance = false
export const useCookiebar = false
export const favicon = {
  tabColor: '#5bbad5',
  tileColor: '#da532c',
  themeColor: '#ffffff',
}
