/* eslint-disable camelcase */
import axios, { AxiosResponse } from 'axios'
import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import Cookies from 'js-cookie'

import { logger } from '@/inc/utils'
import { getApiUrl, LOGIN_TOKEN, USER_TOKEN } from '@/inc/app.config'
import { RootState } from '@/inc/types'
import callApi from '@/composables/fetchapi'
import { userProfile, userLoans, userMortgages, userPackages } from '@/inc/endpoints'
import { report } from '@/composables/reporter'
import { emailSessionUsageKey } from '@/components/popups/EmailUsage.vue'
import i18n from '@/inc/i18n'

type Advance = {
  amount: string
  id: string
}

type Mortgage = {
  id: string
  title: string
  advances: Advance[]
}

type Loan = {
  id: string
  status: string
  subtitle: string
  title: string
  advances: Advance[]
}

type User = {
  address: {
    box: string
    city: string
    country: string
    number: string
    street: string
    zip: string
  }
  box: string
  city: string
  country: string
  number: string
  street: string
  zip: string
  birthdate: string
  contact: {
    email: string
    mobile: string
    phone: string
  }
  email: string
  mobile: string
  phone: string
  firstname: string
  gdprOptin: boolean
  gender: string
  lastname: string
}

interface UserState {
  data: User
  token: string
  error: string
  isLoading: boolean
  hasUser: boolean
  loans: Loan[]
  mortgages: Mortgage[]
  packages: Record<string, unknown>[]
  archivedLoans: Record<string, unknown>[]
}

export let userToken = ''
const defaultUser = () => ({
  address: {
    box: '',
    city: '',
    country: '',
    number: '',
    street: '',
    zip: '',
  },
  box: '',
  city: '',
  country: '',
  number: '',
  street: '',
  zip: '',
  birthdate: '',
  contact: {
    email: '',
    mobile: '',
    phone: '',
  },
  email: '',
  mobile: '',
  phone: '',
  firstname: '',
  gdprOptin: false,
  gender: '',
  lastname: '',
})

const defaultState = () => ({
  data: defaultUser(),
  token: '',
  error: '',
  isLoading: false,
  hasUser: false,
  archivedLoans: [],
  mortgages: [],
  packages: [],
  loans: [],
})

const state: UserState = defaultState()

const getters: GetterTree<UserState, RootState> = {
  loanAmount: state => state.loans.length,
  hasUser: state => state.hasUser,
  user: sate => sate.data,
  loginToken: () => {
    const loginToken = Cookies.get(LOGIN_TOKEN)

    return loginToken?.length ? loginToken : null
  },
  userToken: () => {
    const userToken = Cookies.get(USER_TOKEN)

    return userToken?.length ? userToken : null
  },
}

const mutations: MutationTree<UserState> = {
  SET_STATE(state, payload) {
    Object.assign(state, payload)
  },
  SET_USER(state, payload) {
    if (payload) {
      state.data = { ...payload }
      state.hasUser = true
    } else {
      state.data = defaultUser()
      state.hasUser = false
    }
  },
  SET_ERROR(state, payload) {
    state.error = payload
  },
  IS_LOADING(state, payload) {
    state.isLoading = payload
  },
  SET_TOKEN(state, payload) {
    state.token = payload
    userToken = payload

    if (payload) {
      Cookies.set(USER_TOKEN, payload)
      // Cookies.set(USER_TOKEN, payload, {
      //   domain: `${process.env.VUE_APP_MAIN_DOMAIN}`,
      // })
    }
  },
  SET_LOANS(state, payload) {
    state.loans = payload
  },
  SET_MORTGAGES(state, payload) {
    state.mortgages = payload
  },
  SET_PACKAGES(state, payload) {
    state.packages = payload
  },
}

const actions: ActionTree<UserState, RootState> = {
  LOGOUT({ commit }, { fully } = {}) {
    commit('SET_STATE', defaultState())
    sessionStorage.removeItem(emailSessionUsageKey)

    if (fully) {
      Cookies.remove(USER_TOKEN)
      Cookies.remove(LOGIN_TOKEN)
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  FETCH_CONNECTION_TOKEN({ state }, { nationalNumber, birthday, gender }) {
    // eslint-disable-next-line capitalized-comments
    const number = nationalNumber.replaceAll(/[.]|[-]/g, '')

    return axios
      .post(`${getApiUrl()}/user/send-token`, {
        nationalNumber: number,
        birthday,
        gender,
      })
      .catch(e => {
        const err = new Error('FETCH CONNECTION TOKEN ERROR')

        report(err, {
          title: 'FETCH CONNECTION TOKEN ERROR',
          description: 'API fetch error',
          extra: {
            nationalNumber: number,
            birthday,
            gender,
            user_token: state.token,
            api_response_status: e.response?.status,
            api_response_data: e.response?.data,
          },
        })
      })
  },

  FETCH_LOANS({ commit, dispatch }) {
    dispatch('FETCH_DATA', {
      endpoint: userLoans,
      name: 'FETCH_LOANS',
    }).then(res => {
      const data = res?.data || []
      commit('SET_LOANS', data)

      return data
    })
  },

  FETCH_PACKAGES({ commit, dispatch }) {
    return dispatch('FETCH_DATA', {
      endpoint: userPackages,
      name: 'FETCH_PACKAGES',
    }).then(res => {
      const data = res?.data?.packages || []
      commit('SET_PACKAGES', data)

      return data
    })
  },

  FETCH_MORTGAGES({ commit, dispatch }) {
    dispatch('FETCH_DATA', {
      endpoint: userMortgages,
      name: 'FETCH_MORTGAGES',
    }).then(res => {
      const data = res?.data || []
      commit('SET_MORTGAGES', data)

      return data
    })
  },

  FETCH_MORTGAGE({ dispatch }, id) {
    return dispatch('FETCH_DATA', {
      endpoint: `${userMortgages}/${id}`,
      name: 'FETCH_MORTGAGE',
    })
  },

  FETCH_LOAN({ dispatch }, id) {
    return dispatch('FETCH_DATA', {
      endpoint: `${userLoans}/${id}`,
      name: 'FETCH_MORTGAGE',
    })
  },

  FETCH_DATA({ commit, state }, { endpoint, name }) {
    commit('IS_LOADING', true)

    return callApi(endpoint)
      .then(res => {
        logger.info(name, res?.data)
        commit('SET_ERROR', '')
        commit('IS_LOADING', false)

        return res
      })
      .catch(e => {
        logger.error(name, e)
        const err = new Error(`${name} ERROR`)

        report(err, {
          title: name,
          description: 'API fetch error',
          extra: {
            name,
            endpoint,
            user_token: state.token,
            api_response_status: e.response?.status,
            api_response_data: e.response?.data,
          },
        })

        throw err
      })
  },

  async UPDATE_USER({ dispatch }) {
    await dispatch('FETCH_USER_PROFILE')
  },

  async FETCH_USER_PROFILE({ commit, dispatch }) {
    try {
      const user = await dispatch('FETCH_DATA', {
        endpoint: userProfile,
        name: 'FETCH_USER_PROFILE',
      })

      commit('SET_USER', user.data)
    } catch (error) {
      throw new Error('Error while fetching user data')
    }
  },

  async FETCH_USER(
    { commit, dispatch },
    // eslint-disable-next-line
    { code, state }
  ) {
    try {
      let resToken = ''
      commit('IS_LOADING', true)

      const res: AxiosResponse = await axios.get(`${getApiUrl()}/sso/token`, {
        params: {
          lang: i18n?.locale,
          code,
          state,
        },
      })

      resToken = res.data.userToken

      if (resToken) {
        commit('SET_TOKEN', resToken)
        await dispatch('UPDATE_USER')
      }

      commit('SET_ERROR', '')

      return Promise.resolve()
    } catch (e) {
      commit('IS_LOADING', false)
      commit('SET_ERROR', e)
      commit('SET_USER', null)
      Cookies.remove(USER_TOKEN)
      logger.error('FETCH USER', e)

      const err = new Error('FETCH USER ERROR')

      // eslint-disable-next-line capitalized-comments
      // report(err, {
      //   title: 'FETCH USER ERROR',
      //   description: 'API error while logging in a user',
      //   extra: {
      //     service,
      //     token,
      //     connection_token: connectionToken,
      //     itsMeCode: code,
      //     itsMeSate: state,
      //     api_response_status: e.response?.status,
      //     api_response_data: e.response?.data,
      //   },
      // })

      return Promise.reject(err)
    }
  },
}

export const user: Module<UserState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
